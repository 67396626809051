import { createTheme } from '@mui/material'

// line heights

const lineHeight = 24

// sizes
const rootFontSize = 16
const h1FontSize = 28
const body1FontSize = 16
const body2FontSize = 18
const subtitle1FontSize = 14

// weights
const fontWeightLight = 300
const fontWeightRegular = 400
const fontWeightMedium = 500
const fontWeightBold = 700

// colors
const black = '#353535'
const white = '#FFFFFF'
const body = '#353535'
const primary = '#A240F6'
const secondary = '#65B920'
const lightBlue = '#ECF2F6'

const error = '#fe3b36'

const primaryGradient = 'linear-gradient(90deg, #1FA5FE, #A240F6)'
const primaryGradientHover = 'linear-gradient(90deg, #A240F6, #1FA5FE)'

// fonts
const fontPrimary = 'Poppins'

function lines(lines: number): string {
  return `${(lineHeight * lines) / rootFontSize}rem`
}

const creditScoreIQTheme = createTheme({
  typography: {
    fontFamily: fontPrimary,
    htmlFontSize: rootFontSize,
    fontSize: rootFontSize,
    fontWeightLight,
    fontWeightRegular,
    fontWeightMedium,
    fontWeightBold,
    h1: {
      fontWeight: fontWeightMedium,
      fontSize: `${h1FontSize / rootFontSize}rem`,
      lineHeight: lines(2),
      letterSpacing: 0,
    },
    h2: {
      fontWeight: fontWeightMedium,
      fontSize: '18px',
      lineHeight: lines(1),
      letterSpacing: 0,
    },
    body1: {
      fontSize: body1FontSize,
      letterSpacing: 0,
    },
    body2: {
      fontSize: body2FontSize,
      letterSpacing: 0,
    },
    subtitle1: {
      // small text, disclosures
      fontSize: subtitle1FontSize,
    },
  },
  palette: {
    common: {
      black,
      white,
    },
    error: {
      main: error,
    },
    primary: {
      light: lightBlue,
      main: primary,
      contrastText: white,
    },
    secondary: {
      main: secondary,
      light: '#24A1FE',
    },
    background: {
      paper: '#FFF',
      default: '#FFF',
    },
    text: {
      primary: body,
      secondary: black, // headings
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 14,
          lineHeight: '20px',
          color: '#383838',
          borderRadius: 8,
          padding: '12px 16px',
          backgroundColor: '#F4F6F7',
          filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.25))',
        },
        arrow: {
          color: '#F4F6F7',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          borderRadius: '100px',
          background: primaryGradient,
          backgroundColor: '#A240F6',
          boxShadow: '0 0 20px #A240F64D !important',

          '&:hover': {
            background: primaryGradientHover,
          },
          '&:disabled': {
            opacity: 0.2,
          },
        },
        text: {
          color: `#24A1FE !important`,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&:not(.password-like) .MuiInputBase-root.Mui-error:after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            width: '20px',
            height: '20px',
            right: '15px',
            background: "url('./images/error-mark.svg') no-repeat center",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: fontWeightMedium,
          fontSize: body1FontSize,
        },
        underlineHover: {
          '&:hover': { textDecoration: 'none', color: '#7A7A7A' },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          padding: '3px',
          margin: '-3px',
          color: body,

          '&.Mui-focused': {
            color: body,
          },
          '&:has(~ .Mui-error)': {
            color: error,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          borderColor: black,
          background: '#fff',

          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderWidth: '2px',
          },

          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: black,
          },

          '&.MuiOutlinedInput-adornedEnd': {
            padding: 0,
          },

          '& .MuiInputAdornment-positionEnd': {
            position: 'absolute',
            right: '15px',
          },
          '& .MuiInputBase-inputAdornedEnd': {
            paddingRight: '45px',
          },
        },
        input: {
          letterSpacing: '0.5px',
          color: black,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontWeight: 500,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          background: '#fff',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '& span[class*=radioUnchecked]': {
            border: '2px solid #353535',
          },
          '& span[class*=radioChecked]': {
            border: '2px solid #0094FF',
            boxShadow: 'none',
            position: 'relative',
            '&:after': {
              content: '""',
              position: 'absolute',
              width: '8px',
              height: '8px',
              top: '3px',
              left: '3px',
              background: '#0094FF',
              borderRadius: '50%',
            },
          },
        },
        colorSecondary: {
          '&:hover': {
            backgroundColor: '#F5F6F6 !important',
          },
          '&.Mui-checked:hover': {
            backgroundColor: '#F5F6F6 !important',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          background: 'none !important',
          '& .MuiTouchRipple-root': {
            display: 'none',
          },
        },
      },
    },
  },
})

export type Theme = typeof creditScoreIQTheme
export default creditScoreIQTheme
