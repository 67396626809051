import React, { FunctionComponent } from 'react'
import Head from 'next/head'
import { AppProps } from 'next/app'
import { ThemeProvider, useTheme } from '@mui/material/styles'
import { Provider } from 'react-redux'
import { store } from '@/state'
import { ElasticServerUrl, ElasticServiceName, Title } from '@/constants'
import GtmScript from '@/components/GtmScript'
import { init as initApm, AgentConfigOptions } from '@elastic/apm-rum'
import { getTheme } from 'src/themes/base'
import { Box, CssBaseline, Theme } from '@mui/material'
import Grid from '@mui/material/Grid2'

const apmOptions: AgentConfigOptions = {
  serviceName: ElasticServiceName,
  serverUrl: ElasticServerUrl,
  serviceVersion: `${process.env.NEXT_PUBLIC_ENV}_${process.env.NEXT_PUBLIC_BUILD_ID}`,
  active: process.env.NEXT_PUBLIC_ENV !== 'DEV',
  environment: process.env.NEXT_PUBLIC_ENV,
  breakdownMetrics: true,
  distributedTracingOrigins: [process.env.NEXT_PUBLIC_IDIQ_API_GATEWAY_URL || ''],
  eventsLimit: 160,
}

initApm(apmOptions)
const useStyles = (theme: Theme) => ({
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    maxWidth: '1280px',
    margin: '0',
    padding: '0 10px',
    [theme.breakpoints.up('sm')]: {
      padding: '0 40px',
      margin: '0 auto',
    },
  },
  background: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    background: 'linear-gradient(90deg, rgba(128, 0, 255, 0.03) 0%, rgba(0, 179, 255, 0.03) 100%)',
    filter: 'blur(93px)',
    borderRadius: '33%',
    zIndex: -1,
  },
})

const App: FunctionComponent<AppProps> = (props: AppProps) => {
  const { Component, pageProps } = props
  const theme = useTheme()
  const classes = useStyles(theme)

  /**
   * Remove the server-side injected CSS.
   */
  React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles)
    }
  }, [])

  return (
    <Provider store={store}>
      <Head>
        <title>{Title}</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <link rel="shortcut icon" id="favicon" href={process.env.FAVICON_IMG} />
      </Head>
      <GtmScript />

      <ThemeProvider theme={getTheme(process.env.THEME_NAME as string)}>
        <CssBaseline />
        {process.env.NEXT_PUBLIC_BRAND_CODE === 'CSIQ' && <Box sx={classes.background}></Box>}
        <Grid container sx={classes.bodyContainer}>
          <Component {...pageProps} />
        </Grid>
      </ThemeProvider>
    </Provider>
  )
}

export default App
