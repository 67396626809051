export const tokenReplacer = (string: string, tokens: Record<string, string | number>): string => {
  let result = string

  Object.entries(tokens).forEach(([key, value]) => {
    const regex = new RegExp(`\\{${key}\\}`, 'g')
    result = result.replace(regex, String(value))
  })

  return result
}
