import { DefaultPhoneNumber, LOGIN_URL } from './defaults'

export enum ErrorCodes {
  BlocklistBySSN = '181',
  BlocklistByIPAddress = '187',
  BlocklistByCC = '182',
  InvalidDateOfBirth = 'TU',
  InvalidSSN = '103003',
  PrePaidCard = '615',
  InvalidCC = '615',
  InvalidPaytype = '615',
  ReconfirmNumber = '615',
  InvalidExpiryDate = '615',
  EnrollmentPending = '535',
  GamerLogic = '530',
  IncludePrepaidRedirectOn = '531',
  InvalidCoupon = '051',
  DuplicateMemberBySSN = '520',
  InternalError = '500',
  DuplicateMemberByHousehold = '529',
  SSNIsNotFound = '202',
  SuspiciousPaymentDetails = '221222',
}

export const TUErrorResponseCodes = new Set(['209', '227', '202'])
export const TUCommErrorResponseCodes = new Set([
  '18',
  '28',
  '31',
  '38',
  '51',
  '53',
  '207',
  '208',
  '214',
  '217',
  '218',
  '101003',
  '101103',
])

export const ErrorMessagesMap = {
  [ErrorCodes.EnrollmentPending]:
    'It looks like you may already have an account. If you would like to log in, please select the "Login" button below. Otherwise select the "Submit" button to continue with your enrollment',
  [ErrorCodes.GamerLogic]: `It appears that you've already tried our service.While we're thrilled to have you as a returning member, we regret to inform you that we cannot provide another trial at this time. To continue enjoying our service, please review the monthly price listed in the "Order Summary" section. By proceeding with enrollment, you will be billed immediately.`,
  [ErrorCodes.BlocklistByIPAddress]:
    'Our records indicate that you are not eligible for enrollment at this time. If you have any questions, please contact customer service at ',
  [ErrorCodes.IncludePrepaidRedirectOn]: `We're sorry, at this time we do not support prepaid cards for trial offers. Please use a non-prepaid card for this transaction. If you prefer to use a prepaid card instead, please proceed with a full-price offer.`,
  ['Generic']: `There was a problem with your enrollment. For assistance, please call customer service at ${DefaultPhoneNumber}.`,
  [ErrorCodes.InvalidCoupon]: `The coupon you provided is invalid. Please provide a valid coupon code or contact customer support at ${DefaultPhoneNumber} for further assistance.`,
  [ErrorCodes.InternalError]: `We're sorry, but there was an error processing your request. Please try again in a few minutes. Thanks for your patience and understanding.`,
  ['TUError']: `We are having trouble locating your file at the credit bureaus. Please review your personal information and update it, if anything is not correct. If you continue to have this issue, please contact customer support at ${DefaultPhoneNumber} and select option 2.`,
  ['TUCommError']: `We are unable to process your enrollment attempt at this time. Please re-attempt your enrollment in 24 hours. If you continue to have this issue, please contact customer support at ${DefaultPhoneNumber} and select option 2.`,
  [ErrorCodes.DuplicateMemberBySSN]: `It looks like you are already a member. Please click <a href='${LOGIN_URL}'>here</a> to login.`,
  [ErrorCodes.DuplicateMemberByHousehold]:
    'Only one membership is permitted per household. If you believe there is an alternative offer available or have any questions regarding this restriction, please contact your benefit provider for further assistance.',
  [ErrorCodes.InvalidSSN]: `We're having a hard time locating your file with the credit bureaus. Please review your personal information for accuracy. If you find any errors, please update the information accordingly. If you continue to have this issue, please contact customer support at ${DefaultPhoneNumber} and select option 2.`,
  [ErrorCodes.SuspiciousPaymentDetails]: `We regret to inform you that we are unable to process your enrollment request at this time. Please contact our customer support for further assistance.`,
}

export enum ValidationErrors {
  CCV = "Enter your card's security code",
  CCVLength = 'CVV should be 3-4 digits',
  City = "Only letters, spaces and '/-",
  CreditCardNumber = 'Invalid Credit Card Type. Please check the Credit Card Number Entered.',
  ExpirationDateFormat = 'Not a valid expiration date. Example: MM/YY',
  ExpirationTooFarInFuture = 'Expiration date cannot exceed {MM}/{YYYY}',
  ExpirationDateCannotBeInPast = 'Please enter a date in the future',
  Email = 'Please provide a valid email address',
  EmailFormat = 'Email is not in the correct format',
  EmailConfirmation = 'Emails do not match',
  EmailsShouldNotMatch = 'Email Address and Family Member Email Address should not be same',
  EmailDuplicate = 'Email is already enrolled',
  FamilyEmailSameAsEmail = 'Primary Email Address and Family Member Email Address should not be same',
  Name = "Names can only contain letters, '/-, and spaces",
  MaxLength = 'Must be no more than ${max} characters',
  MiddleInitial = 'Middle initial should be at most one letter',
  MinAge = 'Must be 18 years or older',
  MinLength = 'Must be at least ${min} characters',
  Password = 'Must be at least 8 characters and include a capital, lowercase, number and symbol',
  Phone = 'Phone number must be 10 digits',
  Required = 'This field is required',
  SSN = 'Invalid SSN',
  DateOfBirth = 'Date of Birth format must be MM/DD/YYYY',
  State = 'Select your state',
  StreetAddress = "Only letters, spaces, numbers and -,.'/#",
  Zip = 'Invalid Zip Code',
  ZipLength = 'Zip should be 5 digits',
  Recaptcha = 'Please verify that you’re not a robot',
  TermsRequired = 'Checkbox must be selected',
}
